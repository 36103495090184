<template>
  <auth-card
    :loading="loading"
    :alert="alert"
    @submit="forgotPassword"
  >

    <template #title>
      Forgot Password? 🔒
    </template>

    <template #subtitle>
      Enter your email and we'll send you instructions to reset your password
    </template>

    <template #form>
      <!-- email -->
      <form-validation-field
        ref="emailField"
        v-model="form.email"
        name="email"
        rules="required|email"
        label="Email"
        type="email"
        autofocus
      />
    </template>

    <template #button-text>
      Send reset link
    </template>

    <template #footer>
      <b-link :to="{ name:'auth-login' }">
        <feather-icon icon="ChevronLeftIcon" /> Back to login
      </b-link>
    </template>

  </auth-card>
</template>

<script>
import {
  BLink,
} from 'bootstrap-vue'
import { email as validateEmail, required } from '@validations'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'
import AuthCard from '@/components/auth/AuthCard.vue'

export default {
  components: {
    BLink,
    AuthCard,
    FormValidationField,
  },
  data() {
    return {
      form: {
        email: '',
      },
      loading: false,
      alert: null,
      // validation
      required,
      validateEmail,
    }
  },
  methods: {
    forgotPassword() {
      this.loading = true

      this.$http.post('/auth/forgot-password', this.form)
        .then(() => {
          this.alert = {
            variant: 'success',
            title: 'Success! Check your inbox for the reset password link.',
          }
        })
        .catch(error => {
          this.alert = {
            title: `${error.response.data.message}`,
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => this.loading = false)
    },
  },
}
</script>
